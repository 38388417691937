<template>
  <div>
    <table
      v-if="candle"
      class="table table-google table-highlight w-auto"
    >
      <tbody>
        <tr>
          <td>Status</td>
          <td><candle-status-badge :status="candle.candle_status" /></td>
        </tr>
        <tr>
          <td>Reference</td>
          <td>
            <div class="flex gap-2">
              <icon-star v-if="candle.is_favorite" :checked="candle.is_favorite" />
              <span>{{ candle.candle_reference }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>Date</td>
          <td>{{ candle.candle_date | isodate }}</td>
        </tr>
        <tr>
          <td>Brand</td>
          <td>{{ candle.brand_id ? candle.brand_text : 'None' }}</td>
        </tr>
        <tr>
          <td>Glass</td>
          <td>{{ candle.glass_id ? candle.glass_text : 'None' }}</td>
        </tr>
        <tr>
          <td>Wax</td>
          <td>{{ candle.wax_id ? candle.wax_text : 'None' }}</td>
        </tr>
        <tr>
          <td>Wick</td>
          <td>
            <div class="flex gap-2">
              <span v-if="candle.wick_count > 1">
                {{ candle.wick_count }}x
              </span>
              <span>{{ candle.wick_id ? candle.wick_text : 'None' }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-nowrap">Glass Temp</td>
          <td>{{ candle.glass_temp_id ? candle.glass_temp_text : 'None' }}</td>
        </tr>
        <tr>
          <td class="text-nowrap">Pour Temp</td>
          <td>{{ candle.pour_temp_id ? candle.pour_temp_text : 'None' }}</td>
        </tr>
        <tr>
          <td>Additives</td>
          <td>
            <div v-if="candle.additive.length > 0">
              <div
                v-for="additive in candle.additive"
                :key="additive.id"
                class="flex gap-4 justify-between"
              >
                <div>{{ additive.name_id ? additive.name_text : 'None' }}</div>
                <div>{{ additive.pct_text }}</div>
              </div>
            </div>
            <div v-else>
              None
            </div>
          </td>
        </tr>
        <tr>
          <td>Dyes</td>
          <td>
            <div v-if="candle.dye.length > 0">
              <div
                v-for="dye in candle.dye"
                :key="dye.id"
                class="flex gap-4 justify-between"
              >
                <div>{{ dye.name_id ? dye.name_text : 'None' }}</div>
              </div>
            </div>
            <div v-else>
              None
            </div>
          </td>
        </tr>
        <tr>
          <td>Fragrances</td>
          <td>
            <div v-if="candle.fragrance.length > 0">
              <div
                v-for="fragrance in candle.fragrance"
                :key="fragrance.id"
                class="flex gap-4 justify-between"
              >
                <div>{{ fragrance.name_id ? fragrance.name_text : 'None' }}</div>
                <div>{{ fragrance.pct_text }}</div>
              </div>
            </div>
            <div v-else>
              None
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const CandleStatusBadge = () => import('@/components/candle/CandleStatusBadge.vue');
const IconStar = () => import('@/components/global/IconStar.vue');

export default {
  name: 'CandleInfo',
  components: {
    CandleStatusBadge,
    IconStar,
  },
  props: {
    candle: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  tbody tr > td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  tbody tr>td:nth-child(1) {
    width: 1px;
    padding-left: 0;
    padding-right: 25px;
    color: #575757;
  }
}
</style>
