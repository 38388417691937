<template>
  <div class="candle-note relative">
    <div>
      <div v-if="edit" class="flex flex-col gap-2">
        <b-form-textarea
          v-model="note"
          placeholder="Note"
          rows="3"
          max-rows="6"
          autofocus
        ></b-form-textarea>

        <div class="flex gap-2 items-center">
          <b-btn
            variant="primary"
            size="sm"
            @click="updateNote"
            :disabled="isLoading"

          >
            Save note
          </b-btn>

          <b-btn
            variant="white"
            size="sm"
            @click="cancelNote"
            :disabled="isLoading"
          >
            Cancel note
          </b-btn>
        </div>

      </div>
      <div v-else class="flex flex-col gap-2">
        <div>
          <span v-if="note === null">
            Loading..
          </span>
          <span v-else class="whitespace-pre-line">
            {{ note }}
          </span>
        </div>

        <div class="flex gap-2 items-center">
          <b-btn
            variant="primary"
            size="sm"
            @click="editNote"
            :disabled="isLoading"
          >
            Edit note
          </b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CandleFiles',
  components: {},
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      note: null,
      edit: false,
    };
  },
  methods: {
    editNote() {
      this.edit = true;
    },
    cancelNote() {
      this.edit = false;
      this.fetchNote();
    },
    updateNote() {
      this.loadingCount++;
      const changes = {
        candle_note: this.note,
      };
      this.$http
        .put(`/candle/${this.id}`)
        .send({ changes })
        .then((res) => {
          this.note = res.body.candle_note;
          this.edit = false;
        })
        .catch((err) => {
          this.$toast.error(`Failed to update note: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchNote() {
      this.note = null;
      this.loadingCount++;
      this.$http
        .get(`/candle/${this.id}`)
        .then((res) => {
          this.note = res.body.candle_note;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch note: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.fetchNote();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.candle-note .spinner-container {
  position: absolute;
  top: 10px;
  right: 25px;
}
</style>
