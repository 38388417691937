<template>
  <b-modal
    v-model="data.showModal"
    size="lg"
    button-size="sm"
    modal-class="template-modal"
    title-class="w-full"
    footer-class="w-full"
    no-fade
    @show="onShow"
    @hide="onHide"
    hide-header-close
    scrollable
  >
    <!-- Modal header -->
    <div slot="modal-title">
      <div class="flex w-full justify-between">
        <div>Wick Test</div>
        <div>
          <div v-if="savingCount > 0" class="flex gap-2 items-center">
            <b-spinner label="Spinning" small></b-spinner>
            <span>Saving..</span>
          </div>
          <div v-else>
            Changes are saved automatically.
          </div>
        </div>
      </div>
    </div>

    <!-- Modal content -->
    <div v-if="record" class="flex flex-column gap-4">
        <div>
          <table class="table table-mobile table-sm">
            <tbody>
              <tr>
                <td class="text-nowrap">Sample Number</td>
                <td>
                  {{ record.sample_number }}
                </td>
              </tr>

              <tr>
                <td>Date</td>
                <td>
                  <date-picker
                    class="date-picker"
                    v-model="record.sample_date"
                    format="DD.MM.YYYY"
                    type="date"
                    valueType="YYYY-MM-DD"
                    :clearable="false"
                    :lang="datepickerLocale"
                  />
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Burn Cycles</td>
                <td>
                  <b-form-input
                    v-model="record.burn_cycles"
                    type="number"
                    autocomplete="off"
                    size="sm"
                    trim
                  ></b-form-input>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Fragrance + %</td>
                <td class="px-0 pt-0">
                  <table>
                    <tr
                      v-for="fragrance in record.candle.fragrance"
                      :key="`fragrance_${fragrance.id}`"
                    >
                      <td><span class="mr-3">{{ fragrance.name_text }}</span></td>
                      <td><span>{{ fragrance.pct_text }}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Glass</td>
                <td>
                  {{ record.candle.glass_text }}
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Wax</td>
                <td>
                  {{ record.candle.wax_text }}
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Wick</td>
                <td>
                  <span v-if="record.candle.wick_count > 1">{{ record.candle.wick_count }}x </span>
                  <span>{{ record.candle.wick_text }}</span>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Additive + %</td>
                <td class="px-0 pt-0">
                  <table>
                    <tr
                      v-for="additive in record.candle.additive"
                      :key="`additive_${additive.id}`"
                    >
                      <td><span class="mr-3">{{ additive.name_text }}</span></td>
                      <td><span>{{ additive.pct_text }}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td>Result</td>
                <td>
                  <button-select
                    v-model="record.sample_result"
                    :options="options.sample_result"
                    size="sm"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <template v-for="cycle in record.cycles">
          <table
            class="table table-google"
            :key="cycle.cycle_number"
          >
            <thead>
              <tr>
                <th colspan="2">
                  <div class="flex justify-between">
                    <div>Cycle #{{ cycle.cycle_number }}</div>
                    <div>
                      <b-btn
                        v-if="cycle.cycle_number === record.cycles.length"
                        variant="danger"
                        size="sm"
                        @click="deleteCycle(cycle)"
                      >
                        Delete cycle
                      </b-btn>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="row-fit text-nowrap">Date</td>
                <td>
                  <date-picker
                    class="date-picker"
                    v-model="cycle.cycle_date"
                    format="DD.MM.YYYY HH:mm"
                    type="datetime"
                    valueType="YYYY-MM-DD HH:mm:ss"
                    :clearable="false"
                    :lang="datepickerLocale"
                  />
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Hour 1</td>
                <td>
                  <div class="flex gap-2">
                    <b-form-select
                      v-model="cycle.h1_burn_fail_id"
                      :options="dropdowns.burn_fail"
                      size="sm"
                    ></b-form-select>

                    <b-input-group
                      class="second-select"
                      size="sm"
                      append="°C"
                    >
                      <b-form-input
                        v-model="cycle.h1_glass_temperature"
                        autocomplete="off"
                        size="sm"
                        trim
                        placeholder="Glass temp."
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Hour 2</td>
                <td>
                  <div class="flex gap-2">
                    <b-form-select
                      v-model="cycle.h2_burn_fail_id"
                      :options="dropdowns.burn_fail"
                      size="sm"
                    ></b-form-select>

                    <b-input-group
                      class="second-select"
                      size="sm"
                      append="°C"
                    >
                      <b-form-input
                        v-model="cycle.h2_glass_temperature"
                        autocomplete="off"
                        size="sm"
                        trim
                        placeholder="Glass temp."
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Hour 3</td>
                <td>
                  <div class="flex gap-2">
                    <b-form-select
                      v-model="cycle.h3_burn_fail_id"
                      :options="dropdowns.burn_fail"
                      size="sm"
                    ></b-form-select>

                    <b-input-group
                      class="second-select"
                      size="sm"
                      append="°C"
                    >
                      <b-form-input
                        v-model="cycle.h3_glass_temperature"
                        autocomplete="off"
                        size="sm"
                        trim
                        placeholder="Glass temp."
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Hour 4</td>
                <td>
                  <div class="flex gap-2">
                    <b-form-select
                      v-model="cycle.h4_burn_fail_id"
                      :options="dropdowns.burn_fail"
                      size="sm"
                    ></b-form-select>

                    <b-input-group
                      class="second-select"
                      size="sm"
                      append="°C"
                    >
                      <b-form-input
                        v-model="cycle.h4_glass_temperature"
                        autocomplete="off"
                        size="sm"
                        trim
                        placeholder="Glass temp."
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <div class="grid grid-cols-2 md:grid-cols-4 gap-2 text-nowrap max-h-">
                    <div>
                      <div>Before Burn Front</div>
                      <image-box :data="cycle" field="preburn_front_file_id" />
                    </div>

                    <div>
                      <div>Before Burn Top</div>
                      <image-box :data="cycle" field="preburn_top_file_id" />
                    </div>

                    <div>
                      <div>After Burn Front</div>
                      <image-box :data="cycle" field="postburn_front_file_id" />
                    </div>

                    <div>
                      <div>After Burn Top</div>
                      <image-box :data="cycle" field="postburn_top_file_id" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <div class="flex flex-col gap-2">
          <div>Note</div>
          <div>
            <b-form-textarea
              v-model="record.sample_note"
              rows="6"
              max-rows="12"
            ></b-form-textarea>
          </div>
        </div>

        <div ref="modal-end"></div>
      </div>
      <div v-else-if="isLoading">
        Loading..
      </div>

    <!-- Modal footer -->
    <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
      <div class="flex w-full justify-between">
        <div class="flex gap-2 items-center">
          <b-btn
            size="sm"
            variant="white"
            :disabled="isLoading"
            @click="onClose"
          >
            Close
          </b-btn>

          <b-btn
            size="sm"
            variant="primary"
            :disabled="isLoading || !canStartCycle"
            @click="createCycle()"
          >
            Start new cycle
          </b-btn>
        </div>

        <div class="flex gap-2 items-center">
          <b-btn
            size="sm"
            variant="white"
            :disabled="isLoading"
            @click="printLabel"
          >
            Print label
          </b-btn>

          <b-btn
            size="sm"
            variant="danger"
            :disabled="isLoading"
            @click="deleteRecord"
          >
            Delete
          </b-btn>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  datepickerLocale,
  getLabelPrinter,
  stringToNumber,
} from '@/helpers';
import moment from 'moment';

const _ = require('lodash');

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const ImageBox = () => import('@/components/global/ImageBox.vue');

export default {
  name: 'WicktestModal',
  components: {
    ButtonSelect,
    ImageBox,
  },
  props: {
    data: Object,
    candle_id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (!this.record) return false;
      if (this.isLoading) return false;
      return true;
    },
    preventMessage() {
      return 'You have unsaved changes. Are you sure you want to close?';
    },
    preventClose() {
      return false;
    },
    datepickerLocale() {
      return datepickerLocale;
    },
    stopStatus() {
      const stopIssue = this.dropdowns.burn_fail.find((issue) => issue.text === 'STOP');
      const stopId = stopIssue.value;
      if (!stopIssue) {
        return false;
      }
      for (let i = 0; i < this.record.cycles.length; i++) {
        const cycle = this.record.cycles[i];
        const hasStop = this.failFields.some((failField) => (cycle[failField] === stopId));
        if (hasStop) {
          return true;
        }
      }
      return false;
    },
    changes() {
      const changes = JSON.parse(JSON.stringify(this.record));
      changes.cycles.forEach((cycle) => {
        this.temperatureFields.forEach((temperatureField) => {
          cycle[temperatureField] = stringToNumber(cycle[temperatureField]);
        });
      });
      changes.stop_status = this.stopStatus;
      return changes;
    },
    dropdowns() {
      return this.$store.state.app.dropdowns;
    },
    canStartCycle() {
      if (!this.record) return false;
      if (isNaN(this.record.burn_cycles)) return false;
      return Number(this.record.burn_cycles) > this.record.cycles.length;
    },
  },
  data() {
    return {
      loadingCount: 0,
      savingCount: 0,
      record: null,
      ready: false,
      filters: {},
      options: {
        cold_throw: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
        ],
        flame: [
          { text: 'Underwick', value: 'underwick' },
          { text: 'Correctwick', value: 'correctwick' },
          { text: 'Overwick', value: 'overwick' },
        ],
        jar_temp: [
          { text: '< 70°C', value: 'nominal' },
          { text: '≥ 70°C', value: 'overheat' },
        ],
        melt_pool: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
        ],
        hot_throw: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
        ],
        smoke: [
          { text: 'Yes', value: 1 },
          { text: 'No', value: 0 },
        ],
        sample_result: [
          { text: 'Processing', value: 'processing' },
          { text: 'Pass', value: 'pass' },
          { text: 'Fail', value: 'fail' },
        ],
      },
      scheduleSaveRecord: _.debounce(() => {
        this.saveRecord();
      }, 250),
      temperatureFields: [
        'h1_glass_temperature',
        'h2_glass_temperature',
        'h3_glass_temperature',
        'h4_glass_temperature',
      ],
      failFields: [
        'h1_burn_fail_id',
        'h2_burn_fail_id',
        'h3_burn_fail_id',
        'h4_burn_fail_id',
      ],
    };
  },
  methods: {
    handleScroll() {
      if (!this.record || this.record.cycles.length === 0) {
        return;
      }

      setTimeout(() => {
        const ref = this.$refs['modal-end'];
        ref.scrollIntoView();
      }, 100);
    },

    printLabel() {
      this.loadingCount++;
      this.$http
        .post(`/candle/${this.candle_id}/print_label`)
        .send({ printer_id: getLabelPrinter() })
        .then(() => {
          this.$toast.success('Printed');
        })
        .catch((err) => {
          this.$toast.error(`Failed to print: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    createCycle() {
      const cycle = {
        id: null,
        cycle_number: this.record.cycles.length + 1,
        cycle_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        h1_burn_fail_id: null,
        h2_burn_fail_id: null,
        h3_burn_fail_id: null,
        h4_burn_fail_id: null,
        h1_glass_temperature: '',
        h2_glass_temperature: '',
        h3_glass_temperature: '',
        h4_glass_temperature: '',
        preburn_top_file_id: null,
        preburn_front_file_id: null,
        postburn_top_file_id: null,
        postburn_front_file_id: null,
      };
      this.record.cycles.push(cycle);
      this.handleScroll();
    },
    deleteCycle(deleteCycle) {
      if (!confirm('Do you really wish to delete this cycle?')) {
        return;
      }
      this.record.cycles = this.record.cycles.filter(
        (cycle) => cycle.cycle_number !== deleteCycle.cycle_number,
      );
    },
    deleteRecord() {
      if (!confirm('Do you really want to delete this record?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/sample_wicktest/${this.data.id}`)
        .then(() => {
          this.$emit('updated');
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveRecord() {
      if (!this.changes) {
        return;
      }
      this.loadingCount++;
      this.savingCount++;
      this.$http
        .put(`/sample_wicktest/${this.data.id}`)
        .send({ changes: this.changes })
        .then(() => {
          this.$emit('updated');
        })
        .catch((err) => {
          this.$toast.error(`Failed to update record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.savingCount--;
        });
    },
    fetchRecord() {
      this.loadingCount++;
      this.$http
        .get(`/sample_wicktest/${this.data.id}`)
        .then((res) => {
          this.record = res.body;
          this.ready = true;
          this.handleScroll();
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchRecord();
    },
    onSave() {
      // Implement logic
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    onHide(event) {
      if (this.preventClose && !confirm(this.preventMessage)) {
        event.preventDefault();
      }
    },
  },
  watch: {
    record: {
      deep: true,
      handler() {
        console.log('change');
        if (!this.ready) {
          return;
        }
        this.scheduleSaveRecord();
      },
    },
  },
};
</script>

<style lang="scss">
</style>
