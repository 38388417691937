<template>
  <b-modal
    v-model="data.showModal"
    size="sm"
    button-size="sm"
    modal-class="template-modal"
    title-class="w-full"
    footer-class="w-full"
    no-fade
    @show="onShow"
    @hide="onHide"
    hide-header-close
    hide-footer
    scrollable
  >
    <!-- Modal header -->
    <div slot="modal-title">
      <div class="flex w-full justify-between">
        <div>Labels</div>
        <printer-select />
      </div>
    </div>

    <!-- Modal content -->
    <div class="flex flex-col gap-2">
      <b-btn
        variant="primary"
        size="sm"
        :disabled="isLoading"
        @click="printLink"
      >
        Print QR
      </b-btn>

      <b-btn
        variant="primary"
        size="sm"
        :disabled="isLoading"
        @click="printLabel"
      >
        Print label
      </b-btn>
    </div>

    <!-- Modal footer -->
    <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
      <div class="flex w-full justify-content-between">
        <div>
          <b-btn
            size="sm"
            variant="primary"
            :disabled="isLoading || !canSave"
            @click="onSave"
          >
            Save
          </b-btn>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  getLabelPrinter,
} from '@/helpers';

const PrinterSelect = () => import('@/components/labels/PrinterSelect.vue');

export default {
  name: 'LabelModal',
  components: {
    PrinterSelect,
  },
  props: {
    data: Object,
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
    preventMessage() {
      return 'You have unsaved changes. Are you sure you want to close?';
    },
    preventClose() {
      return false;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {},
    };
  },
  methods: {
    print(endpoint) {
      this.loadingCount++;
      this.$http
        .post(`/candle/${this.id}/${endpoint}`)
        .send({ printer_id: getLabelPrinter() })
        .then(() => {
          this.$toast.success('Printed');
        })
        .catch((err) => {
          this.$toast.error(`Failed to print: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    printLink() {
      this.print('print_link');
    },
    printLabel() {
      this.print('print_label');
    },
    fetchAll() {
      // Implement logic
    },
    onSave() {
      // Implement logic
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    onHide(event) {
      if (this.preventClose && !confirm(this.preventMessage)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
</style>
