<template>
  <b-modal
    v-model="data.showModal"
    size="xl"
    button-size="sm"
    modal-class="template-modal"
    title-class="w-full"
    footer-class="w-full"
    no-fade
    @show="onShow"
    @hide="onHide"
    hide-header-close
    scrollable
  >
    <!-- Modal header -->
    <div slot="modal-title">
      <div class="flex w-full justify-between">
        <div>Burn Test</div>
        <div>
          <div v-if="savingCount > 0" class="flex gap-2 items-center">
            <b-spinner label="Spinning" small></b-spinner>
            <span>Saving..</span>
          </div>
          <div v-else>
            Changes are saved automatically.
          </div>
        </div>
      </div>
    </div>

    <!-- Modal content -->
    <div v-if="record" class="flex flex-column gap-4">
        <div class="grid xl:grid-cols-3 gap-4">
          <table class="table table-mobile table-sm">
            <tbody>
              <tr>
                <td class="text-nowrap">Sample Number</td>
                <td>
                  {{ record.sample_number }}
                </td>
              </tr>

              <tr>
                <td>Date</td>
                <td>
                  <date-picker
                    class="date-picker w-full"
                    v-model="record.sample_date"
                    format="DD.MM.YYYY"
                    type="date"
                    valueType="YYYY-MM-DD"
                    :clearable="false"
                    :lang="datepickerLocale"
                  />
                </td>
              </tr>

              <tr>
                <td class="text-nowrap">Cold Throw</td>
                <td>
                  <button-select
                    v-model="record.cold_throw"
                    :options="options.cold_throw"
                    size="sm"
                    clearable
                  />
                </td>
              </tr>

              <tr>
                <td>Flame</td>
                <td>
                  <button-select
                    v-model="record.flame"
                    :options="options.flame"
                    size="sm"
                    clearable
                  />
                </td>
              </tr>

              <tr>
                <td class="text-nowrap">Melt Pool (hours):</td>
                <td>
                  <button-select
                    v-model="record.melt_pool"
                    :options="options.melt_pool"
                    size="sm"
                    clearable
                  />
                </td>
              </tr>

              <tr>
                <td class="text-nowrap">Hot Throw</td>
                <td>
                  <button-select
                    v-model="record.hot_throw"
                    :options="options.hot_throw"
                    size="sm"
                    clearable
                  />
                </td>
              </tr>

              <tr>
                <td>Smoke</td>
                <td>
                  <button-select
                    v-model="record.smoke"
                    :options="options.smoke"
                    size="sm"
                    clearable
                  />
                </td>
              </tr>

              <tr>
                <td class="text-nowrap">Jar temp.</td>
                <td>
                  <button-select
                    v-model="record.jar_temp"
                    :options="options.jar_temp"
                    size="sm"
                    clearable
                  />
                </td>
              </tr>

              <tr>
                <td class="row-fit">Result</td>
                <td>
                  <button-select
                    v-model="record.sample_result"
                    :options="options.sample_result"
                    size="sm"
                    clearable
                  />
                </td>
              </tr>

              <tr>
                <td>Notes</td>
                <td>
                  <b-form-textarea
                    v-model="record.sample_note"
                    rows="4"
                    max-rows="6"
                    trim
                  ></b-form-textarea>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-span-2 flex flex-col gap-4">
            <div class="grid grid-cols-2 xl:grid-cols-4 gap-4">
              <div>
                <div>Before Burn Front</div>
                <image-box :data="record" field="preburn_front_file_id" />
              </div>

              <div>
                <div>Before Burn Top</div>
                <image-box :data="record" field="preburn_top_file_id" />
              </div>

              <div>
                <div>After Burn Front</div>
                <image-box :data="record" field="postburn_front_file_id" />
              </div>

              <div>
                <div>After Burn Top</div>
                <image-box :data="record" field="postburn_top_file_id" />
              </div>
            </div>
            <div>
              <video-box :data="record" field="video_file_id" />
            </div>
          </div>
        </div>

        <h3 class="text-lg">Gallery</h3>
        <candle-files :id="candle_id" />
      </div>
      <div v-else-if="isLoading">
        Loading..
      </div>

    <!-- Modal footer -->
    <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
      <div class="flex w-full justify-between">
        <b-btn
          size="sm"
          variant="white"
          :disabled="isLoading"
          @click="onClose"
        >
          Close
        </b-btn>
        <div class="flex gap-2 items-center">
          <b-btn
            size="sm"
            variant="white"
            :disabled="isLoading"
            @click="printLabel"
          >
            Print label
          </b-btn>

          <b-btn
            size="sm"
            variant="danger"
            :disabled="isLoading"
            @click="deleteRecord"
          >
            Delete
          </b-btn>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  datepickerLocale,
  getLabelPrinter,
} from '@/helpers';

const _ = require('lodash');

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const ImageBox = () => import('@/components/global/ImageBox.vue');
const VideoBox = () => import('@/components/global/VideoBox.vue');
const CandleFiles = () => import('@/components/candle/CandleFiles.vue');

export default {
  name: 'BurntestModal',
  components: {
    ButtonSelect,
    ImageBox,
    VideoBox,
    CandleFiles,
  },
  props: {
    data: Object,
    candle_id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (!this.record) return false;
      if (this.isLoading) return false;
      return true;
    },
    preventMessage() {
      return 'You have unsaved changes. Are you sure you want to close?';
    },
    preventClose() {
      return false;
    },
    datepickerLocale() {
      return datepickerLocale;
    },
    changes() {
      const changes = JSON.parse(JSON.stringify(this.record));
      return changes;
    },
    dropdowns() {
      return this.$store.state.app.dropdowns;
    },
  },
  data() {
    return {
      loadingCount: 0,
      savingCount: 0,
      record: null,
      ready: false,
      filters: {},
      options: {
        cold_throw: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
        ],
        flame: [
          { text: 'Underwick', value: 'underwick' },
          { text: 'Correctwick', value: 'correctwick' },
          { text: 'Overwick', value: 'overwick' },
        ],
        jar_temp: [
          { text: '< 70°C', value: 'nominal' },
          { text: '≥ 70°C', value: 'overheat' },
        ],
        melt_pool: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
        ],
        hot_throw: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
        ],
        smoke: [
          { text: 'Yes', value: 1 },
          { text: 'No', value: 0 },
        ],
        sample_result: [
          { text: 'Processing', value: 'processing' },
          { text: 'Pass', value: 'pass' },
          { text: 'Fail', value: 'fail' },
        ],
      },
      scheduleSaveRecord: _.debounce(() => {
        this.saveRecord();
      }, 250),
    };
  },
  methods: {
    printLabel() {
      this.loadingCount++;
      this.$http
        .post(`/candle/${this.candle_id}/print_label`)
        .send({ printer_id: getLabelPrinter() })
        .then(() => {
          this.$toast.success('Printed');
        })
        .catch((err) => {
          this.$toast.error(`Failed to print: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteRecord() {
      if (!confirm('Do you really want to delete this record?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/sample_burntest/${this.data.id}`)
        .then(() => {
          this.$emit('updated');
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveRecord() {
      if (!this.changes) {
        return;
      }
      this.loadingCount++;
      this.savingCount++;
      this.$http
        .put(`/sample_burntest/${this.data.id}`)
        .send({ changes: this.changes })
        .then(() => {
          this.$emit('updated');
        })
        .catch((err) => {
          this.$toast.error(`Failed to update record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.savingCount--;
        });
    },
    fetchRecord() {
      this.loadingCount++;
      this.$http
        .get(`/sample_burntest/${this.data.id}`)
        .then((res) => {
          this.record = res.body;
          this.ready = true;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchRecord();
    },
    onSave() {
      // Implement logic
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    onHide(event) {
      if (this.preventClose && !confirm(this.preventMessage)) {
        event.preventDefault();
      }
    },
  },
  watch: {
    record: {
      deep: true,
      handler() {
        if (!this.ready) {
          return;
        }
        this.scheduleSaveRecord();
      },
    },
  },
};
</script>

<style lang="scss">
</style>
