<template>
  <div class="content wick-samples flex flex-column">
    <h1>Wick Testing</h1>

    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between gap-2 w-100">
        <div class="flex gap-8">
          <date-range
            v-model="filters"
            @input="fetchSamples()"
          />

          <div class="flex flex-column gap-1">
            <div>Glass:</div>
            <div>
              <b-form-select
                v-model="filters.glass_id"
                :options="dropdowns.glass"
                size="sm"
                @input="fetchSamples()"
              ></b-form-select>
            </div>
          </div>

          <div class="flex flex-column gap-1">
            <div>Wax:</div>
            <div>
              <b-form-select
                v-model="filters.wax_id"
                :options="dropdowns.wax"
                size="sm"
                @input="fetchSamples()"
              ></b-form-select>
            </div>
          </div>

          <div class="flex flex-column gap-1">
            <div>Wick:</div>
            <div>
              <v-select
                v-model="filters.wick_id"
                :options="dropdowns.wick"
                size="sm"
                label="text"
                :reduce="option => option.value"
                style="width: 200px;"
                @input="fetchSamples()"
              />
            </div>
          </div>

          <div class="flex flex-column gap-1">
            <div>Fragrance:</div>
            <div>
              <v-select
                v-model="filters.fragrance_id"
                :options="dropdowns.fragrance"
                size="sm"
                label="text"
                :reduce="option => option.value"
                style="width: 300px;"
                @input="fetchSamples()"
              />
            </div>
          </div>

          <div class="flex flex-column gap-1">
            <div>Additives:</div>
            <div>
              <v-select
                v-model="filters.additive_id"
                :options="dropdowns.additive"
                size="sm"
                label="text"
                :reduce="option => option.value"
                style="width: 200px;"
                @input="fetchSamples()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-8">
        <div class="flex flex-column gap-1">
          <div>Status:</div>
          <button-select
            v-model="filters.sample_status"
            :options="options.sample_status"
            size="sm"
            @input="fetchSamples()"
          />
        </div>

        <div class="flex flex-column gap-1">
          <div>STOP:</div>
          <button-select
            v-model="filters.stop_status"
            :options="options.stop_status"
            size="sm"
            @input="fetchSamples()"
          />
        </div>
      </div>

      <div class="flex gap-2 justify-end">
          <b-button
            size="sm"
            variant="primary"
            class="text-nowrap"
            @click="createSample()"
            disabled
          >
            New test
          </b-button>
        </div>

      <div class="font-bold">
        Showing {{ samples.length | format }} / {{ sampleCount | format }} sample(s)
      </div>

      <table class="table table-google table-highlight table-weights">
        <thead>
          <tr>
            <th class="text-nowrap">Sample</th>
            <th class="text-nowrap">Date</th>
            <th class="text-nowrap">Glass</th>
            <th class="text-nowrap">Wax</th>
            <th class="text-nowrap">Wick</th>
            <th class="text-nowrap">Wick no.</th>
            <th class="text-nowrap">Fragrance</th>
            <th class="text-nowrap">Cycles</th>
            <th class="text-nowrap">Glass temp.</th>
            <th class="text-nowrap">STOP</th>
            <th class="text-nowrap">Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading || samples.length === 0">
            <td colspan="8">
              <div class="font-bold">
                <span v-if="isLoading">Loading..</span>
                <span v-else-if="samples.length === 0">No samples found</span>
              </div>
            </td>
          </tr>

          <tr v-for="sample in samples" :key="sample.id">
            <td class="row-fit text-nowrap">{{ sample.sample_number }}</td>
            <td class="row-fit text-nowrap">{{ sample.sample_date | isodate }}</td>
            <td class="text-nowrap">
              <span>{{ sample.glass_value }}</span>
            </td>
            <td class="text-nowrap">{{ sample.wax_value }}</td>
            <td class="text-nowrap">{{ sample.wick_value }}</td>
            <td class="text-nowrap">{{ sample.wick_count_value }}</td>
            <td class="text-nowrap">
              <span>{{ sample.fragrance_value }}</span>
              <span v-if="sample.fragrance_pct_value"> / {{ sample.fragrance_pct_value }}</span>
            </td>
            <td class="row-fit text-nowrap">
              <div>{{ sample.cycles_count }} / {{ sample.burn_cycles }}</div>
            </td>
            <td class="row-fit text-nowrap">
              <span v-if="sample.glass_temperature_max.length > 0">
                {{ sample.glass_temperature_max }}°C
              </span>
            </td>
            <td class="row-fit text-nowrap">
              <div v-if="sample.stop_status === true" class="font-bold text-danger">
                STOP
              </div>
            </td>
            <td class="row-fit text-nowrap">
              <div class="flex gap-2 ">
                <b-badge
                  v-if="sample.sample_status === 'pending'"
                  variant="warning"
                  class="w-100"
                >
                  Processing
                </b-badge>
                <b-badge
                  v-if="sample.sample_status === 'pass'"
                  variant="success"
                  class="w-100"
                >
                  Pass
                </b-badge>
                <b-badge
                  v-if="sample.sample_status === 'fail'"
                  variant="danger"
                  class="w-100"
                >
                  Fail
                </b-badge>
              </div>
            </td>
            <td class="row-fit text-nowrap">
              <div class="flex gap-2">
                <b-btn
                  variant="white"
                  size="sm"
                  @click="duplicateSample(sample)"
                >
                  Duplicate
                </b-btn>

                <b-btn
                  variant="primary"
                  size="sm"
                  @click="editSample(sample)"
                >
                  Edit
                </b-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <b-pagination
        v-model="filters.page"
        :total-rows="sampleCount"
        :per-page="filters.per_page"
        @input="fetchSamples(true)"
        :disabled="isLoading"
      ></b-pagination>

    </div>

    <sample-modal
      v-if="sampleModal.showModal"
      :data="sampleModal"
      :options="options"
      @change="fetchSamples()"
      @updated="onUpdated"
      @deleted="onDeleted"
    />
  </div>
</template>

<script>
const DateRange = () => import('@/components/invoices/DateRange.vue');
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const SampleModal = () => import('@/components/wick_samples/SampleModal.vue');

export default {
  name: 'ScaleSamples',
  components: {
    SampleModal,
    ButtonSelect,
    DateRange,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    dropdowns() {
      return this.$store.state.app.dropdowns;
    },
  },
  data() {
    return {
      loadingCount: 0,
      samples: [],
      sampleCount: 0,
      filters: {
        page: 1,
        per_page: 50,
        fragrance_id: null,
        fragrance_pct_id: null,
        glass_id: null,
        glass_diameter_id: null,
        wax_id: null,
        wick_count_id: null,
        wick_id: null,
        additive_id: null,
        additive_pct_id: null,
        sample_status: null,
        stop_status: null,
      },
      options: {
        sample_status: [
          { value: null, text: 'All' },
          { value: 'pending', text: 'Processing', activeVariant: 'warning' },
          { value: 'pass', text: 'Pass', activeVariant: 'success' },
          { value: 'fail', text: 'Fail', activeVariant: 'danger' },
        ],
        stop_status: [
          { value: null, text: 'All' },
          { value: 'yes', text: 'Yes' },
          { value: 'no', text: 'No' },
        ],
      },
      sampleModal: {
        showModal: false,
        sampleId: null,
      },
    };
  },
  methods: {
    fetchSampleData() {
      this.loadingCount++;
      this.$http
        .get('/wick_sample/samples')
        .query(this.filters)
        .then((res) => {
          this.samples = res.body.samples;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch samples: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchSampleCount() {
      this.loadingCount++;
      this.$http
        .get('/wick_sample/samples_count')
        .query(this.filters)
        .then((res) => {
          this.sampleCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch sample count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchSamples(paginate, scroll) {
      paginate = paginate === undefined ? false : paginate;
      scroll = scroll === undefined ? true : scroll;
      if (paginate) {
        if (scroll) {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 50);
        }
      } else {
        this.fetchSampleCount();
        this.filters.page = 1;
      }
      this.fetchSampleData();
    },
    createSample() {
      this.loadingCount++;
      this.$http
        .post('/wick_sample/samples')
        .then((res) => {
          this.fetchSamples();
          this.editSample(res.body.sample);
        })
        .catch((err) => {
          this.$toast.error(`Failed to create sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    editSample(sample) {
      this.sampleModal.sampleId = sample.id;
      this.sampleModal.showModal = true;
    },
    duplicateSample(sample) {
      this.loadingCount++;
      this.$http
        .post(`/wick_sample/samples_duplicate/${sample.id}`)
        .then((res) => {
          this.fetchSamples();
          this.editSample(res.body.sample);
        })
        .catch((err) => {
          this.$toast.error(`Failed to duplicate sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onUpdated() {
      this.fetchSamples(true, false);
    },
    onDeleted(deletedSample) {
      this.samples = this.samples.filter((sample) => sample.id !== deletedSample.id);
      this.sampleCount--;
    },
  },
  created() {
    this.fetchSamples();
  },
};
</script>

<style lang="scss" scoped>
  .wick-samples {
    max-width: 1350px;
  }
</style>
