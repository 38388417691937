<template>
  <div class="content">
    <a href="http://service.wedowick.de/?lang=en#WickSelector" target="_blank">
      Open Wick Configurator
    </a>
  </div>
</template>

<script>
export default {
  name: 'WickConfigurator',
  components: {},
  data() {
    return {};
  },
  methods: {
    openWebsite() {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: 'http://service.wedowick.de/?lang=en#WickSelector',
      }).click();
    },
  },
  created() {
    this.openWebsite();
  },
};
</script>

<style lang="scss" scoped>
</style>
