<template>
  <b-modal
    v-model="data.showModal"
    size="sm"
    button-size="sm"
    modal-class="template-modal"
    title-class="w-full"
    footer-class="w-full"
    no-fade
    @show="onShow"
    hide-header-close
    hide-footer
    scrollable
  >
    <!-- Modal header -->
    <div slot="modal-title">
      <div class="flex w-full justify-between">
        New sample
      </div>
    </div>

    <!-- Modal content -->
    <div class="flex flex-col gap-2">
      <b-btn
        v-for="type in types" :key="type.value"
        variant="primary"
        size="sm"
        :disabled="isLoading"
        @click="createSample(type.value)"
      >
        {{ type.text }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CreateModal',
  components: {},
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      types: [
        { text: 'Wick Test', value: 'wicktest' },
        { text: 'Burn Test', value: 'burntest' },
      ],
    };
  },
  methods: {
    createSample(type) {
      this.$emit('create', type);
      this.onClose();
    },
    onShow() {
      // no-op
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
