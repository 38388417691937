import {
  hasArrayDuplicates,
  encodeFilters,
} from '@/helpers';

export function getCandleIssues(candle) {
  if (!candle) return [];
  const issues = [];
  if (candle.candle_date.length === 0) {
    issues.push('candle_date');
  }
  if (candle.candle_status.length === 0) {
    issues.push('candle_status');
  }
  if (candle.brand_id === null) {
    issues.push('brand_id');
  }
  if (candle.glass_id === null) {
    issues.push('glass_id');
  }
  if (candle.wax_id === null) {
    issues.push('wax_id');
  }
  if (candle.wick_id === null) {
    issues.push('wick_id');
  }
  if (candle.glass_temp_id === null) {
    issues.push('glass_temp_id');
  }
  if (candle.pour_temp_id === null) {
    issues.push('pour_temp_id');
  }
  const wickCount = parseInt(candle.wick_count, 10);
  if (isNaN(wickCount) || wickCount < 0) {
    issues.push('wick_count');
  }
  let additivesFilled = candle.additive.length > 0 && candle.additive.every((additive) => (
    additive.name_id !== null && additive.pct_id !== null
  ));
  // Additives - None
  if (candle.additive.length === 1 && candle.additive[0].name_id === 0) {
    additivesFilled = true;
  }
  if (additivesFilled) {
    const additivesUnique = !hasArrayDuplicates(candle.additive.map((item) => item.name_id));
    if (!additivesUnique) {
      issues.push('additive');
    }
  } else {
    issues.push('additive');
  }
  let dyesFilled = candle.dye.length > 0 && candle.dye.every((dye) => (
    dye.name_id !== null
  ));
  // Dyes - None
  if (candle.dye.length === 1 && candle.dye[0].name_id === 0) {
    dyesFilled = true;
  }
  if (dyesFilled) {
    const dyesUnique = !hasArrayDuplicates(candle.dye.map((item) => item.name_id));
    if (!dyesUnique) {
      issues.push('dye');
    }
  } else {
    issues.push('dye');
  }
  let fragrancesFilled = candle.fragrance.length > 0 && candle.fragrance.every((fragrance) => (
    fragrance.name_id !== null && fragrance.pct_id !== null
  ));
  // Fragrances - None
  if (candle.fragrance.length === 1 && candle.fragrance[0].name_id === 0) {
    fragrancesFilled = true;
  }
  if (fragrancesFilled) {
    const fragrancesUnique = !hasArrayDuplicates(candle.fragrance.map((item) => item.name_id));
    if (!fragrancesUnique) {
      issues.push('fragrance');
    }
  } else {
    issues.push('fragrance');
  }
  return issues;
}

export function isCandleValid(candle) {
  const issues = getCandleIssues(candle);
  return issues.length === 0;
}

export const defaultFilters = {
  date_from: null,
  date_to: null,
  date_range: 'all',
  page: 1,
  per_page: 50,
  search: '',
  brand_id: null,
  glass_id: null,
  wax_id: null,
  wick_id: null,
  wick_count_id: null,
  glass_temp_id: null,
  pour_temp_id: null,
  dye_name_id: null,
  fragrance_name_id: null,
  additive_name_id: null,
  dye_pct_id: null,
  fragrance_pct_id: null,
  additive_pct_id: null,
  candle_status: null,
  is_favorite: null,
};

export function getCandleLink(candle, filters) {
  return `/candles/${candle.id}?filters=${encodeFilters(filters, defaultFilters)}`;
}
