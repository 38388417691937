export function moveUpArray(array, index) {
  if (index === 0) return;
  const data = array[index];
  array.splice(index, 1);
  array.splice(index - 1, 0, data);
}

export function moveDownArray(array, index) {
  if (index === array.length - 1) return;
  const data = array[index];
  array.splice(index, 1);
  array.splice(index + 1, 0, data);
}

export function hasArrayDuplicates(array) {
  return (new Set(array)).size !== array.length;
}

export function getArrayDuplicates(array) {
  return [...new Set(array.filter((item, index) => array.indexOf(item) !== index))];
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function encodeFilters(filters, defaultFilters) {
  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(([key]) => filters[key] !== defaultFilters[key]),
  );
  const encoded = btoa(JSON.stringify(filteredFilters));
  return encoded;
}

export function decodeFilters(encoded, defaultFilters) {
  const decoded = JSON.parse(atob(encoded));
  return { ...defaultFilters, ...decoded };
}

export function validateLabelProperties(label) {
  const propertiesValid = (
    label.label_name.length > 0
  );
  return propertiesValid;
}

export function validateLabelField(field) {
  const fieldValid = (
    field.field_name.length > 0
    && field.field_row.toString().length > 0
    && isNaN(field.field_row) === false
    && parseInt(field.field_row, 10) >= 0
    && field.field_column.toString().length > 0
    && isNaN(field.field_column) === false
    && parseInt(field.field_column, 10) >= 0
    && parseInt(field.field_column, 10) <= 100
  );
  return fieldValid;
}

export function validateLabelFields(label) {
  const fieldsValid = label.fields.every((field) => validateLabelField(field));
  return fieldsValid;
}

export function validateLabel(label) {
  if (label == null) return false;
  const propertiesValid = validateLabelProperties(label);
  if (!propertiesValid) return false;
  const fieldsValid = validateLabelFields(label);
  if (!fieldsValid) return false;
  return true;
}

export function getClickEventIncrement(event) {
  if (event.shiftKey) return 20;
  if (event.ctrlKey) return 5;
  return 1;
}

export function sortLabelFields(fields) {
  return fields.sort((a, b) => (a.field_row - b.field_row || a.field_column - b.field_column));
}

export function zipArrays(a, b) {
  return a.map((k, i) => [k, b[i]]);
}

export function hasWhitespace(text) {
  return /\s/g.test(text);
}

export function generateGuid() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);
}

export function getColorLuminance(hex) {
  const rgb = parseInt(hex.slice(0, 6), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance;
}

export function isColorDark(hex) {
  const luminance = getColorLuminance(hex);
  return luminance < 150;
}

export function findNearestColor(hex, colors) {
  // Convert the input hex to an RGB color object
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const color = { r, g, b };

  // Calculate Delta E for each color in the colors array
  let minDeltaE = Infinity;
  let closestIndex = -1;
  for (let i = 0; i < colors.length; i++) {
    const c = {
      r: parseInt(colors[i].substring(0, 2), 16),
      g: parseInt(colors[i].substring(2, 4), 16),
      b: parseInt(colors[i].substring(4, 6), 16),
    };
    const deltaE = Math.sqrt(
      (c.r - color.r) ** 2 * 0.299
      + (c.g - color.g) ** 2 * 0.587
      + (c.b - color.b) ** 2 * 0.114,
    );
    if (deltaE < minDeltaE) {
      minDeltaE = deltaE;
      closestIndex = i;
    }
  }

  return closestIndex;
}

export function downloadFile(link) {
  const anchor = document.createElement('a');
  anchor.href = link;
  anchor.target = '_blank';
  let download = link.split('/').pop();
  download = download.replaceAll('%20', ' ');
  anchor.download = download;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

export function roundNumber(value, minimumFractionDigits, maximumFractionDigits) {
  if (isNaN(value)) {
    return '0';
  }
  minimumFractionDigits = minimumFractionDigits === undefined ? 0 : minimumFractionDigits;
  maximumFractionDigits = maximumFractionDigits === undefined ? 0 : maximumFractionDigits;
  return value.toLocaleString('cs-CZ', {
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

export function clampNumber(number, min, max) {
  return Math.max(min, Math.min(number, max));
}

export function roundThree(value) {
  const rounded = Number(value).toLocaleString('cs-CZ', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });
  return rounded;
}

export function numberToString(number) {
  return Number(number).toLocaleString('cs-CZ');
}

export function stringToNumber(number) {
  return Number(String(number).replaceAll(',', '.').replaceAll(/\s/g, ''));
}

export function formatStandardDate(standardDate) {
  const date = new Date(standardDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function formatDuration(seconds) {
  const units = {
    hour: 3600,
    minute: 60,
  };
  const hours = Math.floor(seconds / units.hour);
  seconds %= units.hour;
  const minutes = Math.floor(seconds / units.minute);
  seconds %= units.minute;
  const result = [];
  if (hours > 0) result.push(`${hours}h`);
  if (minutes > 0) result.push(`${minutes}m`);
  if (seconds > 0 && result.length === 0) result.push(`${seconds}s`);
  return result.join(' ');
}

export function buildDropdowns(groups) {
  const noneDropdowns = [
    'additive',
    'additive_label',
    'candle_brand',
    'fragrance',
    'wax',
    'dye',
    'wick',
  ];
  const noneOption = { text: 'None', value: 0 };
  const dropdowns = {};
  Object.values(groups).forEach((group) => {
    const options = group.options.map((option) => ({
      text: `${group.prefix}${option.value}${group.suffix}`,
      value: option.id,
    }));
    if (noneDropdowns.includes(group.key)) {
      options.unshift(noneOption);
    }
    dropdowns[group.key] = options;
  });
  return dropdowns;
}

export function shortNumber(number, otherNumbers) {
  if (!number || isNaN(number)) return number;
  otherNumbers = otherNumbers === undefined ? [] : otherNumbers;
  const allNumbers = [number, ...otherNumbers];
  const largestNumber = Math.max(...allNumbers);

  const suffixes = ['', 'k', 'm', 'b'];

  const thresholdIndex = Math.floor(Math.log(largestNumber) / Math.log(1000));
  const matchedSuffix = suffixes[thresholdIndex];

  if (matchedSuffix !== undefined) {
    return (number / (1000 ** thresholdIndex)).toFixed(2).replace('.', ',').replace(',00', '') + matchedSuffix;
  }
  return number.toString();
}

export function shortNumberChart(number, data) {
  if (typeof data === 'object' && 'dataPointIndex' in data && data.dataPointIndex >= 0) {
    const otherNumbers = data.series.map((serie) => serie[data.dataPointIndex]);
    return shortNumber(number, otherNumbers);
  }
  return shortNumber(number);
}

export function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.slice(0, maxLength)}...`;
}

export function getFirstLine(text) {
  const sliceIndex = text.indexOf('\n');
  if (sliceIndex === -1) {
    return text;
  }
  return text.slice(0, sliceIndex);
}

export function popWindow(url) {
  const w = 550;
  const h = 550;
  const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
  const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
  const popoutWindow = window.open(url, 'Obrázek', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
  popoutWindow.focus();
}

export function getLabelPrinter() {
  let id = localStorage.getItem('labelPrinterId');
  if (id === null || isNaN(id)) {
    return null;
  }
  id = parseInt(id, 10);
  return id;
}

export function setLabelPrinter(id) {
  localStorage.setItem('labelPrinterId', id === null ? '' : id);
}

export function calculateDropdownPosition(dropdownList, component) {
  setTimeout(() => {
    const componentRect = component.$el.getBoundingClientRect();
    const dropdownHeight = dropdownList.getBoundingClientRect().height;
    let positionTop = componentRect.top + componentRect.height;
    const dropdownOverflow = positionTop + dropdownHeight > window.innerHeight;
    if (dropdownOverflow) {
      positionTop = componentRect.top - dropdownHeight;
    }
    dropdownList.style.top = `${positionTop}px`;
    dropdownList.style.left = `${componentRect.left}px`;
    dropdownList.style.width = `${componentRect.width}px`;
  }, 0);
}

export function getDefaultChartOptions() {
  return {
    chart: {
      animations: {
        speed: 300,
      },
      fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      toolbar: {
        show: false,
      },
      type: 'bar',
      zoom: {
        enabled: false,
      },
    },
    colors: [
      '#0a5bcc',
      '#ff0c0c',
    ],
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: [
        '#0a5bcc',
        '#ff0c0c',
      ],
      opacity: 1,
      type: 'solid',
    },
    grid: {
      show: true,
    },
    legend: {
      show: false,
    },
    stroke: {
      colors: [
        '#0a5bcc',
        '#ff0c0c',
      ],
      curve: 'smooth',
      width: 3,
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      followCursor: true,
      shared: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        hideOverlappingLabels: false,
        trim: true,
      },
    },
    yaxis: {
      labels: {
        formatter: shortNumberChart,
        offsetY: 2,
      },
    },
  };
}

const fileTypes = {
  jpg: 'image',
  jpeg: 'image',
  png: 'image',
  webm: 'video',
  mp4: 'video',
  ogg: 'video',
  mov: 'video',
};
export function getFileType(file) {
  return fileTypes[file.ext] ?? 'generic';
}

const fileSizeUnits = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export function formatFileSize(fileSize) {
  if (fileSize === null || fileSize === 0) {
    return '';
  }
  const index = Math.floor(Math.log(fileSize) / Math.log(1024));
  const size = (fileSize / (1024 ** index)).toFixed(2).replace('.', ',');
  const unit = fileSizeUnits[index];
  const displaySize = `${size} ${unit}`;
  return displaySize;
}

export const datepickerLocale = {
  formatLocale: {
    firstDayOfWeek: 1,
  },
};
