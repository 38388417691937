<template>
  <b-modal
    v-model="data.showModal"
    size="lg"
    button-size="sm"
    modal-class="template-modal"
    title-class="w-full"
    footer-class="w-full"
    no-fade
    @show="onShow"
    @hide="onHide"
    hide-header-close
    scrollable
  >
    <!-- Modal header -->
    <div slot="modal-title">
      <div class="flex w-full justify-between">
        Edit candle
      </div>
    </div>

    <!-- Modal content -->
    <div class="flex flex-col gap-2">
      <table class="table table-google table-highlight" v-if="candle">
        <tbody>
          <tr>
            <td class="row-fit">
              <span>Status</span>
            </td>
            <td>
              <div class="flex justify-end">
                  <button-select
                    v-model="candle.candle_status"
                    :options="options.candle_status"
                    size="sm"
                  />
              </div>
            </td>
          </tr>
          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('candle_date') }">
                Date
              </span>
            </td>
            <td>
              <div class="flex justify-end">
                <date-picker
                  class="date-picker"
                  v-model="candle.candle_date"
                  format="DD.MM.YYYY"
                  type="date"
                  valueType="YYYY-MM-DD"
                  :clearable="false"
                  :lang="datepickerLocale"
                >
                </date-picker>
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('brand_id') }">
                Brand
              </span>
            </td>
            <td>
              <div>
                <v-select
                  v-model="candle.brand_id"
                  :options="dropdowns.candle_brand"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                  class="w-100"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('glass_id') }">
                Glass
              </span>
            </td>
            <td>
              <div>
                <v-select
                  v-model="candle.glass_id"
                  :options="dropdowns.glass"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                  class="w-100"
                  @option:selected="onGlassChange"
                />
                <option-note :id="candle.glass_id" />
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('wax_id') }">
                Wax
              </span>
            </td>
            <td>
              <div>
                <v-select
                  v-model="candle.wax_id"
                  :options="dropdowns.wax"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                  class="w-100"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('wick_id') }">
                Wick
              </span>
            </td>
            <td>
              <div>
                <v-select
                  v-model="candle.wick_id"
                  :options="dropdowns.wick"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                  class="w-100"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit text-nowrap">
              <span :class="{ 'candle-issue': candleIssues.includes('wick_count') }">
                Wick Count
              </span>
            </td>
            <td>
              <div>
                <b-form-input
                  v-model="candle.wick_count"
                  type="number"
                  size="sm"
                  min="0"
                  max="10"
                  autocomplete="off"
                ></b-form-input>
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('glass_temp_id') }">
                Glass Temp
              </span>
            </td>
            <td>
              <div>
                <v-select
                  v-model="candle.glass_temp_id"
                  :options="dropdowns.glass_temp"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                  class="w-100"
                />
                <option-note :id="candle.glass_temp_id" />
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('pour_temp_id') }">
                Pour Temp
              </span>
            </td>
            <td>
              <div>
                <v-select
                  v-model="candle.pour_temp_id"
                  :options="dropdowns.pour_temp"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                  class="w-100"
                />
                <option-note :id="candle.pour_temp_id" />
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit align-top">
              <span :class="{ 'candle-issue': candleIssues.includes('additive') }">
                Additives
              </span>
            </td>
            <td>
              <div class="flex flex-col gap-2">
                <div v-for="(additive, index) in candle.additive" :key="`additive_${index}`">
                  <div class="flex gap-2 items-center">
                    <v-select
                      v-model="additive.name_id"
                      :options="dropdowns.additive"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      :class="
                        (additive.name_id !== null && additive.name_id !== 0)
                          ? 'w-8/12'
                          : 'w-100'
                      "
                    />
                    <v-select
                      v-if="additive.name_id !== null && additive.name_id !== 0"
                      v-model="additive.pct_id"
                      :options="dropdowns.additive_pct"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      class="w-4/12"
                    />
                    <b-btn
                      size="sm"
                      variant="danger"
                      @click="deleteExtra('additive', index)"
                      title="Delete"
                    >
                      &#x2715;
                    </b-btn>
                  </div>
                  <option-note :id="additive.name_id" />
                </div>
                <div>
                  <b-btn
                    size="sm"
                    variant="link"
                    @click="addExtra('additive')"
                    class="pl-0"
                  >
                    Add additive
                  </b-btn>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit align-top">
              <span :class="{ 'candle-issue': candleIssues.includes('dye') }">
                Dyes
              </span>
            </td>
            <td>
              <div class="flex flex-col gap-2">
                <div v-for="(dye, index) in candle.dye" :key="`dye_${index}`">
                  <div class="flex gap-2 items-center">
                    <v-select
                      v-model="dye.name_id"
                      :options="dropdowns.dye"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      class="w-100"
                      appendToBody
                    />
                    <b-btn
                      size="sm"
                      variant="danger"
                      @click="deleteExtra('dye', index)"
                      title="Delete"
                    >
                      &#x2715;
                    </b-btn>
                  </div>
                  <option-note :id="dye.name_id" />
                </div>
                <div>
                  <b-btn
                    size="sm"
                    variant="link"
                    @click="addExtra('dye')"
                    class="pl-0"
                  >
                    Add dye
                  </b-btn>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit align-top">
              <span :class="{ 'candle-issue': candleIssues.includes('fragrance') }">
                Fragrances
              </span>
            </td>
            <td>
              <div class="flex flex-col gap-2">
                <div v-for="(fragrance, index) in candle.fragrance" :key="`fragrance_${index}`">
                  <div class="flex gap-2 items-center">
                    <v-select
                      v-model="fragrance.name_id"
                      :options="dropdowns.fragrance"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      :class="
                        (fragrance.name_id !== null && fragrance.name_id !== 0)
                          ? 'w-8/12'
                          : 'w-100'
                      "
                      appendToBody
                    />
                    <v-select
                      v-if="fragrance.name_id !== null && fragrance.name_id !== 0"
                      v-model="fragrance.pct_id"
                      :options="dropdowns.fragrance_pct"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      class="w-4/12"
                      appendToBody
                    />
                    <b-btn
                      size="sm"
                      variant="danger"
                      @click="deleteExtra('fragrance', index)"
                      title="Delete"
                    >
                      &#x2715;
                    </b-btn>
                  </div>
                  <option-note :id="fragrance.name_id" />
                </div>
                <div>
                  <b-btn
                    size="sm"
                    variant="link"
                    @click="addExtra('fragrance')"
                    class="pl-0"
                  >
                    Add fragrance
                  </b-btn>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal footer -->
    <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
      <div class="flex w-full justify-between">
        <b-btn
          size="sm"
          variant="white"
          :disabled="isLoading"
          @click="onClose"
        >
          Close
        </b-btn>
        <div>
          <div v-if="savingCount > 0" class="flex gap-2 items-center">
            <b-spinner label="Spinning" small></b-spinner>
            <span>Saving..</span>
          </div>
          <div v-else-if="!canSave" class="font-bold text-red-500">
            Changes won't be saved until fixed.
          </div>
          <div v-else-if="error" class="font-bold text-red-500">
            {{ error }}
          </div>
          <div v-else>
            Changes are saved automatically.
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import _ from 'lodash';
import { datepickerLocale } from '@/helpers';
import { getCandleIssues, isCandleValid } from '@/composables/candle';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const OptionNote = () => import('@/components/select/OptionNote.vue');

export default {
  name: 'EditModal',
  components: {
    ButtonSelect,
    OptionNote,
  },
  props: {
    data: Object,
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    candleIssues() {
      return getCandleIssues(this.candle);
    },
    canSave() {
      return isCandleValid(this.candle);
    },
    preventMessage() {
      return 'You have invalid changes. Are you sure you want to close without saving?';
    },
    preventClose() {
      return !this.canSave || this.error !== null;
    },
    dropdowns() {
      return this.$store.state.app.dropdowns;
    },
    datepickerLocale() {
      return datepickerLocale;
    },
  },
  data() {
    return {
      savingCount: 0,
      loadingCount: 0,
      filters: {},
      options: {
        candle_status: [
          { text: 'Discarded', value: 'discarded', activeVariant: 'danger' },
          { text: 'Testing', value: 'testing', activeVariant: 'warning' },
          { text: 'Production', value: 'production', activeVariant: 'success' },
        ],
      },
      candle: null,
      error: null,
      scheduleSaving: _.debounce(() => {
        this.onSave();
      }, 250),
    };
  },
  methods: {
    addExtra(extraField) {
      this.candle[extraField].push({
        id: null,
        name_id: null,
        pct_id: null,
      });
    },
    deleteExtra(extraField, index) {
      this.candle[extraField].splice(index, 1);
    },
    fetchCandle() {
      this.loadingCount++;
      this.$http
        .get(`/candle/${this.id}`)
        .then((res) => {
          this.candle = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch candle: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchCandle();
    },
    onGlassChange(glass) {
      const glassId = glass.value;
      this.$http
        .get(`/select/options/${glassId}`)
        .then((res) => {
          this.candle.wick_count = parseInt(res.body.wick_count, 10);
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch wick count: ${err.response.text}`);
        });
    },
    onSave() {
      if (!this.canSave) {
        return;
      }
      this.savingCount++;
      this.loadingCount++;
      this.$http
        .put(`/candle/${this.id}`)
        .send({ changes: this.candle })
        .then((res) => {
          this.error = null;
          this.$emit('updated', res.body);
        })
        .catch((err) => {
          this.error = err.response.text;
        })
        .finally(() => {
          this.savingCount--;
          this.loadingCount--;
        });
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    onHide(event) {
      if (this.preventClose && !confirm(this.preventMessage)) {
        event.preventDefault();
      }
    },
  },
  watch: {
    candle: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue === null) {
          return;
        }
        this.scheduleSaving();
      },
    },
  },
};
</script>

<style lang="scss">
</style>
